import css from "../../css/header/logo.module.css";

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Link from "next/link";
import Image from "next/image";

import logoSm from "../../static/img/logo-sm.png";
import logoSmWhite from "../../static/img/logo-sm-white.png";
import logoLg from "../../static/img/logo-lg.png";
import logoXl from "../../static/img/logo-xl.png";
import logoXlBlue from "../../static/img/logo-xl-blue.png";

const logoSources = {
    sm: logoSm,
    smwhite: logoSmWhite,
    lg: logoLg,
    xl: logoXl,
    xlblue: logoXlBlue,
};

const logoSize = {
    sm: [140, 24],
    smwhite: [140, 24],
    lg: [210, 36],
    xl: [693, 116],
    xlblue: [693, 116],
};

const Logo = (props) => {
    const { color, size } = props;

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(false);
    }, []);

    const logoType = color ? size + color : size;

    if (isLoading) {
        return <></>;
    }

    return (
        <Link href="/" passHref legacyBehavior>
            <Image
                src={logoSources[logoType]}
                width={logoSize[logoType][0]}
                height={logoSize[logoType][1]}
                alt="Residential.com"
                className={css[size]}
            />
        </Link>
    );
};

Logo.propTypes = {
    size: PropTypes.string,
    color: PropTypes.string,
};

Logo.defaultProps = {
    size: "sm",
    color: null,
};

export default Logo;
