import { withRouter } from "next/router";
import cx from "classnames";
import Link from "next/link";
import React, { Children } from "react";

const ActiveLink = ({
    router,
    children,
    href,
    as,
    activeClassName,
    prefix,
    forceActive = false,
    ...otherProps
}) => {
    const child = Children.only(children);
    const asPathParts = router.asPath.split("?");
    let activeByPrefix = false;

    if (prefix) {
        if (!Array.isArray(prefix)) {
            prefix = [prefix];
        }

        activeByPrefix =
            prefix.filter((p) => router.asPath.startsWith(p)).length > 0;
    }

    const className = cx(child.props.className, {
        [activeClassName]:
            forceActive ||
            ((asPathParts[0] === as || activeByPrefix) && activeClassName),
    });

    return (
        <Link href={href} as={as} {...otherProps} legacyBehavior>
            {React.cloneElement(child, { className })}
        </Link>
    );
};

export default withRouter(ActiveLink);
