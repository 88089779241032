import ActiveLink from "../common/ActiveLink";

import React from "react";
import PropTypes from "prop-types";

const MenuItem = (props) => {
    const { label, href, as, children, activeClassName, forceActive, prefix } =
        props;

    return (
        <li>
            <ActiveLink
                activeClassName={activeClassName}
                href={href}
                as={as}
                prefix={prefix}
                forceActive={forceActive}
            >
                <span>{label}</span>
            </ActiveLink>
            {children}
        </li>
    );
};

MenuItem.defaultProps = {
    forceActive: false,
    prefix: null,
};

MenuItem.propTypes = {
    label: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    as: PropTypes.string,
    activeClassName: PropTypes.string,
    forceActive: PropTypes.bool,
    prefix: PropTypes.any,
};

export default MenuItem;
