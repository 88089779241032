import "react-toastify/dist/ReactToastify.css";

import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";

export const NOTIFICATION_SUCCESS = "success";
export const NOTIFICATION_INFO = "info";
export const NOTIFICATION_WARNING = "warning";
export const NOTIFICATION_ERROR = "error";

let loadingIds = [];

export const toastLoading = (message) => {
    const id = toast.loading(message);
    loadingIds.push(id);
};

export const toastCloseLoading = () => {
    if (loadingIds.length > 0) {
        loadingIds.forEach((id) => {
            toast.dismiss(id);
        });

        loadingIds = [];
    }
};

export const toastSuccess = (message) => {
    toastCloseLoading();
    toast.success(message);
};

export const toastInfo = (message) => {
    toastCloseLoading();
    toast.info(message);
};

export const toastWarning = (message) => {
    toastCloseLoading();
    toast.warning(message);
};

export const toastError = (message) => {
    toastCloseLoading();
    toast.error(message);
};

export const toastPerType = (message = "", type = "") => {
    if (message) {
        if (type === NOTIFICATION_INFO) {
            toastInfo(message);
        } else if (type === NOTIFICATION_WARNING) {
            toastWarning(message);
        } else if (type === NOTIFICATION_ERROR) {
            toastError(message);
        } else {
            toastSuccess(message);
        }
    } else {
        toastCloseLoading();
    }
};

const ToastNotification = () => {
    const [id, setId] = useState("");

    useEffect(() => {
        setId("_" + Math.random().toString(36).substr(2, 9));
    }, []);

    return (
        <ToastContainer
            containerId={id}
            position={toast.POSITION.TOP_RIGHT}
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
    );
};

export default ToastNotification;
