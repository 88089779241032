import TwitterIcon from "mdi-react/TwitterIcon";
import FacebookIcon from "mdi-react/FacebookIcon";

import React from "react";
import PropTypes from "prop-types";
import Link from "next/link";

const Copyright = (props) => {
    const { theme } = props;

    return <>
        <div className={theme.copyrightText}>
            &copy; {new Date().getFullYear()} Residential.com | Follow us on
            <div className={theme.socialLinks}>
                <a
                    href="https://www.facebook.com/residentialcom"
                    target="resi_fb"
                    className={theme.facebook}
                    role="button"
                    aria-label="Follow us on Facebook"
                >
                    <FacebookIcon size={20} />
                </a>
                <a
                    href="https://www.twitter.com/Residentialdot"
                    target="resi_twitter"
                    className={theme.twitter}
                    role="button"
                    aria-label="Follow us on Twitter"
                >
                    <TwitterIcon size={20} />
                </a>
            </div>
        </div>
        <div className={theme.copyrightLinks}>
            <Link
                href="/static-pages?page=corporate"
                as="/about/corporate"
                className={theme.copyrightLink}>
                About
            </Link>
            <Link
                href="/static-pages?page=terms-of-use"
                as="/about/terms_of_use"
                className={theme.copyrightLink}>
                Terms of Use
            </Link>
            <Link
                href="/static-pages?page=privacy-policy"
                as="/about/privacy_policy"
                className={theme.copyrightLink}>
                Privacy Policy
            </Link>
            <Link href="/sitemap" className={theme.copyrightLink}>
                Site map
            </Link>
        </div>
    </>;
};

Copyright.propTypes = {
    theme: PropTypes.object.isRequired,
};

export default Copyright;
